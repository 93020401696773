import React from "react"
import styled from "styled-components"
import BGImg from "gatsby-background-image"
import { colors, H1White, buttonThree, H3White } from "../../styles/helpers"
import { Link } from "gatsby"
import { createSlug } from "../../utils/helperFunc"

const HeroCalloutSection = styled.section`
  position: relative;
  min-height: 40rem;
  @media (min-width: 768px) {
    min-height: 55rem;
  }

  @media (min-width: 1025px) {
    min-height: 65.3rem;
  }
  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 100rem;
    margin: auto;
    padding: 6rem;
    transform: translate(-50%, -50%);
    z-index: 5;

    .title {
      text-align: center;

      h2 {
        ${H1White};
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        display: inline-block;
        border-bottom: 0.1rem solid ${colors.colorSecondary};
      }
    }

    .content {
      text-align: center;
      p {
        ${H3White};
      }
    }

    .link {
      text-align: center;

      a {
        ${buttonThree};
      }
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    div {
      width: 100%;
      height: 100%;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #262525;
    opacity: 0.5;
    z-index: 1;
  }
`

const HeroCallout = ({ heroCall }) => {
  const linkUrl = createSlug(heroCall.acf._coo_hercal_button_link)
  return (
    <HeroCalloutSection>
      <div className="wrapper">
        <div className="title">
          <h2>{heroCall.acf._coo_hercal_title}</h2>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: heroCall.acf._coo_hercal_content }}
        />
        <div className="link">
          <Link to={`/${linkUrl}`}>{heroCall.acf._coo_hercal_button_text}</Link>
        </div>
      </div>
      <div className="background">
        <BGImg
          tag="div"
          fluid={heroCall.acf._coo_hercal_image.localFile.childImageSharp.fluid}
        />
      </div>
      <div className="overlay" />
    </HeroCalloutSection>
  )
}

export default HeroCallout
