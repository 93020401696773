import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { H2Green, B1Black, colors } from "../../styles/helpers"

const VisionBeliefsSection = styled.section`
  padding: 2rem 0;

  .visionWrap,
  .beliefsWrap {
    position: relative;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }

  .beliefsWrap {
    flex-direction: column-reverse;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .visionGraphic {
    position: absolute;
    top: -5rem;
    left: 0;
    bottom: -5rem;
    width: 100%;
    background-color: ${colors.colorAccent};
    z-index: -1;

    @media (min-width: 1025px) {
      top: -20rem;
      bottom: -5rem;
    }
  }

  .visionImage,
  .belifesImage {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50%);
    }
    @media (min-width: 1025px) {
      width: calc(40%);
    }
  }

  .visionContent,
  .belifesContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50%);
    }
    @media (min-width: 1025px) {
      width: calc(60%);
    }

    &__inner {
      position: relative;
      max-width: 70rem;
      background-color: ${colors.white};
      z-index: 5;

      &--content {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 2rem;
        background-color: ${colors.white};
        z-index: 5;

        @media (min-width: 768px) {
          padding: 5rem 2rem 5rem 15rem;
        }

        @media (min-width: 1025px) {
          padding: 7.5rem 2rem 7.5rem 15rem;
        }
      }

      &--graphic {
        position: absolute;
        top: 2rem;
        right: -2rem;
        width: calc(100% + 2rem);
        height: 100%;
        background-color: ${colors.colorAlt};
        opacity: 0.44;
        z-index: 1;
      }
    }

    h2 {
      ${H2Green};
    }

    p {
      ${B1Black};
    }
  }

  .belifesContent {
    justify-content: flex-end;

    &__inner {
      position: relative;
      max-width: 70rem;
      background-color: ${colors.white};
      z-index: 5;

      &--content {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 5rem 10rem 5rem 2rem;
        background-color: ${colors.white};
        z-index: 5;

        @media (min-width: 1025px) {
          padding: 7.5rem 15rem 7.5rem 2rem;
        }
      }
    }
  }
`

const VisionBeliefs = ({ visionBeliefs }) => {
  const beliefsContent = visionBeliefs.acf._coo_visbel_beliefs
  const beliefsImage =
    visionBeliefs.acf._coo_visbel_beliefs_image.localFile.childImageSharp.fluid
  const visionContent = visionBeliefs.acf._coo_visbel_vision
  const visionImage =
    visionBeliefs.acf._coo_visbel_vision_image.localFile.childImageSharp.fluid
  return (
    <VisionBeliefsSection>
      <div className="visionWrap">
        <div className="visionImage">
          <Img fluid={visionImage} alt="Our Vision" />
        </div>
        <div className="visionContent">
          <div className="visionContent__inner">
            <div className="visionContent__inner--content">
              <div>
                <h2>Our Vision</h2>
              </div>
              <div dangerouslySetInnerHTML={{ __html: visionContent }} />
            </div>
            <div className="visionContent__inner--graphic" />
          </div>
        </div>
        <div className="visionGraphic" />
      </div>
      <div className="beliefsWrap">
        <div className="belifesContent">
          <div className="belifesContent__inner">
            <div className="belifesContent__inner--content">
              <div>
                <h2>Our Beliefs</h2>
              </div>
              <div dangerouslySetInnerHTML={{ __html: beliefsContent }} />
            </div>
          </div>
        </div>
        <div className="belifesImage">
          <Img fluid={beliefsImage} alt="Our Beliefs" />
        </div>
      </div>
    </VisionBeliefsSection>
  )
}

export default VisionBeliefs
