import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Hero from "../components/Westmark/Hero"
import CommunitiesBlock from "../components/Westmark/CommunitiesBlock"
import VisionBeliefs from "../components/Westmark/VisionBeliefs"
import HeroCallout from "../components/Westmark/HeroCallout"

const Westmark = props => {
  const { seoInfo, hero, communities, visionBeliefs, heroCall } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={location}
      />
      <Hero hero={hero} />
      <CommunitiesBlock communities={communities} />
      <VisionBeliefs visionBeliefs={visionBeliefs} />
      <HeroCallout heroCall={heroCall} />
    </Layout>
  )
}

export const westmarkQuery = graphql`
  query westmarkPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    hero: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hohr_bg {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hohr_title
        _coo_hohr_content
      }
    }

    communities: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_comblo_title
        _coo_comblo_content
        _coo_comblo_image_one {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_comblo_image_two {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    visionBeliefs: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_visbel_vision
        _coo_visbel_vision_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_visbel_beliefs
        _coo_visbel_beliefs_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    heroCall: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hercal_title
        _coo_hercal_content
        _coo_hercal_button_text
        _coo_hercal_button_link
        _coo_hercal_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1614) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default Westmark
