import React from "react"
import styled from "styled-components"
import BgImg from "gatsby-background-image"
import { medWrapper, H2Green, B1Black } from "../../styles/helpers"

const CommunitiesBlockSection = styled.section`
  @media (min-width: 1025px) {
    padding: 12rem 0;
  }
  .wrapper {
    ${medWrapper};
  }

  .contentBlock {
    width: 100%;
    margin-bottom: 5rem;

    @media (min-width: 768px) {
      width: calc(50%);
      margin-bottom: 0;
    }

    &__inner {
      @media (min-width: 1025px) {
        width: 100%;
        max-width: 50rem;
        margin-right: 0;
        margin-bottom: 20rem;
        margin-left: auto;
      }
    }

    &__title {
      h2 {
        ${H2Green};
      }
    }

    &__content {
      p {
        ${B1Black};
      }
    }

    &__imageTwo {
      width: 100%;
      min-height: 30rem;

      @media (min-width: 768px) {
        height: 50rem;
      }

      div {
        width: 100%;
        height: 100%;
        min-height: 30rem;
      }
    }
  }

  .imageOne {
    position: relative;
    width: 100%;
    min-height: 30rem;

    @media (min-width: 768px) {
      width: calc(50%);
    }

    @media (min-width: 1025px) {
      width: calc(50% - 6rem);
      margin-left: 6rem;
      margin-bottom: 15rem;
    }

    div {
      width: 100%;
      height: 100%;
    }
  }
`

const CommunitiesBlock = ({ communities }) => {
  const title = communities.acf._coo_comblo_title
  const content = communities.acf._coo_comblo_content
  const imageOne =
    communities.acf._coo_comblo_image_one.localFile.childImageSharp.fluid
  const imageTwo =
    communities.acf._coo_comblo_image_two.localFile.childImageSharp.fluid

  return (
    <CommunitiesBlockSection>
      <div className="wrapper">
        <div className="contentBlock">
          <div className="contentBlock__inner">
            <div className="contentBlock__title">
              <h2>{title}</h2>
            </div>
            <div
              className="contentBlock__content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
          <div className="contentBlock__imageTwo">
            <BgImg tag="div" fluid={imageTwo} />
          </div>
        </div>
        <div className="imageOne">
          <BgImg tag="div" fluid={imageOne} />
        </div>
      </div>
    </CommunitiesBlockSection>
  )
}

export default CommunitiesBlock
